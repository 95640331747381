/* eslint-env es6*/
'use strict';

import Alpine from 'alpinejs';
import Cookies from 'js-cookie';
// import ClipboardJS from 'clipboard';
import intersect from '@alpinejs/intersect';

window.Alpine = Alpine;
Alpine.magic('showmodal', function () {
  return Cookies.get('cookieshown') === undefined;
});
Alpine.plugin(intersect);
Alpine.start();

var clipboard = new ClipboardJS('.clippy');

clipboard.on('success', function (e) {
  el = document.querySelector('.clippy-text');
  el.innerHTML = 'Copied!';
  window.setTimeout(function () {
    el.innerHTML = 'Copy Key to Clipboard';
  }, 3000);
});

if (Cookies.get('cookieshown') === undefined) {
  document.querySelector('#cookieModal').classList.remove('hidden');
}

window.setTimeout(function () {
  const nodes = document.querySelector('#alertbox');
  if (nodes) {
    nodes.classList.add('hidden');
  }
}, 6000);

nodes = document.querySelector('#cookieAccept');
if (nodes) {
  nodes.addEventListener('click', function () {
    Cookies.set('cookieshown', '1', { expires: 365 });
  });
}

nodes = document.querySelector('#cookieLearn');
if (nodes) {
  nodes.addEventListener('click', function () {
    Cookies.set('cookieshown', '1', { expires: 365 });
  });
}

if (document.querySelector('.home-page')) {
  import('./home.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.docs-page')) {
  import('./docs.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.code-page')) {
  import('./sourcecode.js').then(function (page) {
    page.render();
  });
}

if (document.querySelector('.dash-page')) {
  import('./dashboard.js').then(function (page) {
    page.render(mapOpts);
  });
}
